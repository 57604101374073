@font-face {
  font-family: rooneysansbold;
  src: url(./fonts/RooneySans-Bold-webfont.woff);
}
@import url('https://fonts.googleapis.com/css?family=Satisfy&display=swap');
*,
*:after,
*:before {
  box-sizing: border-box;
}
html {
  height: 100%;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #333;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
  > iframe {
    // this fixes weird scrollbar
    float: left;
  }
}
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}
.login-bg-mobile {
  -webkit-transform: scaleX(-1);
  display: none;
  margin-top: 24px;
  transform: scaleX(-1);
  width: 150px;
}
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  &.mb {
    margin-bottom: 30px;
  }
}
a {
  color: #4d40ff;
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
}
textarea,
select,
input,
button {
  outline: none;
}
.back-to-login {
  display: block;
  margin-top: 20px;
  font-size: 10px;
  font-weight: 700;
}
header {
  transition: all 0.4s ease;
  width: 100%;
  background-color: #f8f8f8;
  border-color: #e7e7e7;
  top: 0;
  border-width: 0 0 1px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid #e7e7e7;
  display: flex;
  justify-content: space-between;

  .container {
    min-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    .navbar-header {
      float: left;
      padding: 15px;
      a img {
        margin: auto;
      }
    }
    ul.navbar-nav {
      float: right;
      li {
        display: inline;
        a {
          padding: 15px 12px 10px;
          color: #777;
          font-weight: 400;
          margin: 12px 0 12px 15px;
          text-transform: uppercase;
          line-height: 40px;
          font-size: 12px;
          &:hover {
            text-decoration: underline;
            color: #333;
          }
        }
      }
    }

    .logo {
      display: block;
      width: 140px !important;
      height: 37px !important;
    }

    button {
      display: none;
      background: transparent;
      border: 0;
      width: 50px;
      align-items: center;
      cursor: pointer;
    }

    button span,
    button span:before,
    button span:after {
      cursor: pointer;
      border-radius: 1px;
      height: 5px;
      width: 35px;
      background: #666;
      position: absolute;
      display: block;
      content: '';
      -webkit-transition: all 300ms ease-in-out;
      -o-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
    }
    button span:before {
      top: -10px;
    }
    button span:after {
      bottom: -10px;
    }
    button.active span {
      background: transparent;
    }
    button.active span:before {
      top: 0;
      transform: rotate(45deg);
    }
    button.active span:after {
      top: 0;
      transform: rotate(-45deg);
    }
  }
}

@media (max-width: 992px) {
  .container {
    //width: 970px;
  }
}

@media (max-width: 768px) {
  header {
    .container {
      //width: 768px;
    }
    .container > .navbar-header,
    .container > .navbar-collapse,
    .container-fluid > .navbar-header,
    .container-fluid > .navbar-collapse {
      margin-right: 0;
      margin-left: 0;
    }

    .container {
      padding: 0;
      .navbar-header {
        display: flex;
        width: 100%;

        button {
          display: flex;
        }
        a {
          flex-grow: 1;
          text-align: center;
        }
      }
      ul.navbar-nav {
        float: left;
        margin: 0;
        width: 100%;
        li {
          display: block;
          a {
            display: block;
            width: 100%;
            padding: 1px 10px;
          }
        }
        &.closed {
          display: none;
        }
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) and (max-width: 768px) {
  .navbar .navbar-collapse ul {
    background-color: #e7e7e7;
    background-image: url(images/pureprofile-menu-logo@2x.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-background-size: 190px 260px;
    background-size: 190px 260px;
  }
}

@media screen and (max-width: 768px) {
  .navbar .navbar-collapse ul {
    background-color: #e7e7e7;
    background-image: url(images/pureprofile-menu-logo.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-background-size: 190px 260px;
    background-size: 190px 260px;
  }
}

.container-flex {
  display: flex;
  flex-grow: 1;

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    //padding: 20px;\
    width: 100%;

    &.signup {
      background: url(images/bg1-w1000.png) no-repeat center center;
      background-size: cover;
    }
    &.login {
      background: url(images/bg2-w1000.png) no-repeat center center;
      background-size: cover;
    }
    &.validateemail {
      background: url(images/bg2-w1000.png) no-repeat center center;
      background-size: cover;
    }
    &.reset-password {
      background: url(images/reset-password-bg.jpg) no-repeat center center;
      background-size: cover;
    }
  }
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  //font-family: rooneysansbold,sans-serif;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 33px;
  color: #6abeba;
}

p {
  font-family: 'Open Sans', sans-serif;
  margin: 10px 0;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  &.font-light {
    color: #9ea3ad;
  }
  &.font-larger {
    font-size: 24px;
  }
}

.image-bg {
  flex-grow: 1;
  background: url(images/image-bg.jpg) no-repeat center center;
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .email-sent-container {
    width: 600px;
    background: #495267;
    padding: 10px;
    p {
      color: #fff !important;
      font-family: 'Open Sans', sans-serif;
      font-size: 27px;
      font-weight: 300;
      line-height: 32px;
    }
    a {
      color: #1b86b0;
      font-size: 13px;
    }
    .text-content {
      width: 70%;
      text-align: center;
      margin: 60px auto;
      h1 {
        font-family: 'Satisfy', cursive;
        color: #62d3c8;
        font-size: 60px;
      }
    }
  }
}
.dark-bg {
  height: 100%;
  background: #495267;
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
  overflow: auto;
  img {
    width: 150px;
    margin-top: 100px;
  }
  h3,
  p,
  label {
    color: #fff !important;
  }
  a {
    color: #1b86b0;
    font-size: 13px;
  }
  h3 {
    line-height: 40px;
  }
  .forgot-password-container {
    text-align: center;
    .helper-text {
      width: 340px;
      margin: 20px auto;

      p {
        font-size: 14px;
      }
    }
    .register-login-form {
      margin: 0 auto;
      width: 340px;
      margin-bottom: 30px;
    }
  }
}
.register-login-form {
  //display: flex;
  width: 450px;
  text-align: left;
  &.mt {
    margin-top: 50px;
  }
  label {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin: 0 0 5px;
    display: block;
  }
  .p-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.2em;
  }
  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.2em;
    margin: 10px 0 25px;
    &.google-err {
      font-weight: 300;
      font-size: 10px;
      margin-top: 0;
    }
  }

  .input-field {
    position: relative;
    svg {
      position: absolute;
      top: 34px;
      right: 10px;
    }
  }

  input {
    color: #4d40ff;
    height: 35px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border: 1px solid #bbb;
    border-radius: 5px;
    margin-bottom: 20px !important;
    padding-left: 15px;
    width: 100%;
    &[type='checkbox'] {
      width: 20px;
      margin: 0;
      height: 14px;
    }
  }

  .error-message {
    background: #ef4100;
    border-radius: 5px;
    color: white;
    text-transform: uppercase;
    padding: 0.2em 0.7em 0.3em 0.7em;
    span {
      font-size: 10px;
      svg {
        position: static !important;
      }
    }
  }

  .error-message-input {
    position: relative;
    top: -5px;
    margin-top: -13px;
  }

  .select2,
  span.select2 {
    width: 100% !important;
  }

  .social-login {
    span.icon {
      color: #fff;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      position: relative;
      font-size: 18px;
      float: left;
      padding: 8px 10px;
    }
    .btn-social {
      display: block;
      border-radius: 3px;
      -webkit-box-shadow: 0 2px 0 0 #ddd;
      box-shadow: 0 2px 0 0 #ddd;
      font-weight: 600;
      text-align: left;
      font-size: 14px;
      line-height: 38px;
      min-height: 38px;
      text-indent: 15px;
      color: #fff;
      cursor: pointer;
      &.btn-fb {
        background: #4b6ea9;
      }
      &.btn-tw {
        background: #24c3ec;
      }
      &.btn-gp {
        background: #cd3c28;
      }
    }
    .cursor-wait {
      cursor: wait;
    }
    .disable-click {
      pointer-events: none;
    }
  }
}

div.tac {
  clear: both;
  &.fixed {
    position: fixed;
    bottom: 20px;
    max-width: 44%;
  }
  label {
    cursor: pointer;
  }
  label,
  p {
    font-size: 12px;
    margin: 20px 0 0;
    font-weight: 400;
    text-align: center;
    a {
      text-decoration: underline;
    }
  }
}

.submit {
  font-size: 16px;
  color: #fff;
  width: 100%;
  border: none;
  border-radius: 4px;
  background: #6cbfbb !important;
  -webkit-box-shadow: 0 4px 0 0 #6cbfbb;
  box-shadow: 0 4px 0 0 #6cbfbb;
  height: 40px !important;
  //margin: 20px 0;
  margin-bottom: 0 !important;
  margin-top: 30px;
  padding: 2px 0;
  font-weight: 700;
  cursor: pointer;
  font-family: inherit;
  &:hover {
    background: #6cbfbb !important;
  }
  &:disabled {
    background: #ccc !important;
    -webkit-box-shadow: 0 4px 0 0 #ccc !important;
    box-shadow: 0 4px 0 0 #ccc !important;
    cursor: default;
  }
  &.inverse {
    align-items: center;
    color: #6cbfbb;
    background: #fff !important;
    display: flex;
    justify-content: center;
    width: 100%;
    box-shadow: 8px 6px 14px #484747;
    webkit-box-shadow: 8px 6px 14px #484747;
    padding: 8px;
    padding-top: 9px;
    &.mobile {
      width: 100%;
      display: block;
      margin: 0;
      box-shadow: none;
      border: 1px solid #dad8d8;
      text-align: center;
      display: none;
    }
  }
}

.left-wrapper,
.right-wrapper {
  float: left;
}

.left-wrapper {
  width: 50%;
  margin-right: 14%;
}
.right-wrapper {
  width: 36%;
}

@media screen and (max-width: 900px) {
  .register-login-form {
    margin-bottom: 40px;
  }
  div.tac {
    position: relative;
    bottom: 0px;
    max-width: 100%;
    &.fixed {
      position: relative;
      max-width: 100%;
      margin-top: 20px;
    }
  }
  .submit.inverse.mobile {
    display: block;
  }
  .login-bg-mobile {
    display: block;
  }
  .flex.right {
    display: none;
  }
  h3 {
    &.mb {
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 640px) {
  .left-wrapper {
    width: 100% !important;
    margin-right: 0;
  }
  .right-wrapper {
    width: 100%;
  }
  .register-login-form {
    width: 320px;
  }
  .image-bg {
    .email-sent-container {
      width: 250px;
      margin-top: 150px;
      .text-content {
        margin: 30px auto;
        h1 {
          font-family: 'Satisfy', cursive;
          color: #62d3c8;
          font-size: 60px;
        }
        p {
          font-size: 23px;
        }
      }
    }
  }
}

@media screen and (max-width: 330px) {
  .main-wrapper {
    padding: 0;
  }
  .register-login-form {
    width: 300px;
  }
  .submit {
    font-size: 14px;
  }
  .dark-bg {
    .forgot-password-container {
      .helper-text {
        width: 300px;
      }
      .register-login-form {
        width: 300px;
      }
    }
  }
}
